<template>
    <div>
      <h1
        class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
        style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
      >
        <h6 class="h2 text-white d-inline-block mb-0">
          <i class="ni ni-single-02"></i> Company Type
        </h6>
      </h1>
  
      <base-header class="pb-6">
        <b-row>
          <b-col xl="11" md="11"> </b-col>
        </b-row>
      </base-header>
  
      <b-container fluid class="mt--6">
        <b-row>
          <b-col xl="12">
            <b-card>
              <template v-slot:header>
                <b-row>
                  <b-col lg="6" cols="7">
                    <!-- <h6 class="surtitle">Overview</h6>
                  <h5 class="h3 mb-0">Total sales</h5> -->
                  </b-col>
                  <b-col
                    lg="6"
                    cols="7"
                    style="display: flex; justify-content: flex-end"
                  >
                    <base-button
                      size="md"
                      type="primary"
                      @click="handleAdd()"
                    >
                      <i class="fa fa-plus" aria-hidden="true"></i>
                      Tambah Tipe Perusahaan
                    </base-button>
                  </b-col>
                </b-row>
              </template>
              <!-- <PublikasiTable /> -->
              <el-table
                :data="listData"
                row-key="id"
                header-row-class-name="thead-light"
              >
              <el-table-column label="No" type="index" width="100" />
  
                <el-table-column min-width="180px" label="Nama">
                  <div slot-scope="action">
                    {{ action.row.name }}
                  </div>
                </el-table-column>
  
                <el-table-column min-width="180px" label="Deskripsi">
                  <div slot-scope="action">
                    {{ action.row.description }}
                  </div>
                </el-table-column>
  
  
                <el-table-column min-width="180px" label="Aksi">
                  <div slot-scope="action">
                    <base-button type="warning" size="sm" icon @click="handleUpdate(action.row)">
                      UBAH
                    </base-button>
                    <base-button type="danger" size="sm" icon @click="doDelete(action.row)">
                      HAPUS
                    </base-button>
                  </div>
                </el-table-column>
              </el-table>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
  
      <!-- Tambah Publikasi -->
      <Modal
        :show="showAddPublicationModal"
        modalContentClasses="mdl-content"
        modalClasses="modal-dialog-scrollable"
        headerClasses="pb-0"
        @close="showAddPublicationModal = false"
        size="lg"
      >
        <template v-slot:header>
          <div class="modal-title">
            <h4 class="mdl-title">Tambah Jenis Perusahaan</h4>
          </div>
        </template>
        <div class="px-4 border-top pt-4">
          <div class="form-group row">
            <div class="col-lg-12">
              <label class="col-form-label form-control-label">Name</label>
              <div>
                    <input type="text" class="form-control" v-model="nameInput" />
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-12">
              <label class="col-form-label form-control-label">Description</label>
              <div>
                    <input type="text" class="form-control" v-model="descriptionInput" />
              </div>
            </div>
          </div>

          
          
          <div class="form-group row">
            <button class="btn btn-primary" @click="doAdd">Simpan</button>
          </div>
        </div>
      </Modal>
  
      <!-- Edit Publikasi -->
      <Modal
        :show="showEditPublicationModal"
        modalContentClasses="mdl-content"
        modalClasses="modal-dialog-scrollable"
        headerClasses="pb-0"
        @close="showEditPublicationModal = false"
        size="lg"
      >
      <template v-slot:header>
          <div class="modal-title">
            <h4 class="mdl-title">Edit Jenis Perusahaan</h4>
          </div>
        </template>
        <div class="px-4 border-top pt-4">
          <div class="form-group row">
            <div class="col-lg-12">
              <label class="col-form-label form-control-label">Name</label>
              <div>
                <input type="text" class="form-control" v-model="editnameInput" />
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-lg-12">
              <label class="col-form-label form-control-label">Description</label>
              <div>
                <input type="text" class="form-control" v-model="editdescriptionInput" />
              </div>
            </div>
          </div>
          
  
          
          <div class="form-group row">
            <button class="btn btn-primary" @click="doEdit">UBAH</button>
          </div>
        </div>
      </Modal>
    </div>
  </template>
  
  <script>
  import API from "../../../api/base_url";
  import {Table, TableColumn, Select, Option } from "element-ui";
  import BaseHeader from "@/components/BaseHeader";
  import { BasePagination } from "@/components";
  import clientPaginationMixin from "../../Tables/PaginatedTables/clientPaginationMixin"
  import PublikasiTable from "../../Tables/AdminTable/Publikasi.vue";
  import Modal from "@/components/Modal.vue";
  import HtmlEditor from "@/components/Inputs/HtmlEditor";
  import { format } from "date-fns";
  import Swal from "sweetalert2";
  import "sweetalert2/dist/sweetalert2.css";
  
  
  export default {
    mixins: [clientPaginationMixin],
    components: {
      BaseHeader,
      BasePagination,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      PublikasiTable,
      Modal,
      HtmlEditor,
  
    },
    mounted() {
      this.getData()
    },
    data() {
      return {
        // add Modal
        nameInput:"",
        descriptionInput:"",
        // edit Modal
        editnameInput:"",
        editdescriptionInput:"",
        editId:"",
        // others
        listData:[],
        showAddPublicationModal: false,
        showEditPublicationModal: false,
        selectedRows: [],
        // category
        provideSelect: {
          question:[],
        },
      };
    },
    methods: {
      getData() {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
          API.get(`comp-types` , {headers})
            .then(({data}) => {
              if (data.data == null) {
                this.listData = []
              } else {
                this.listData = data.data
              }
            })
            .catch((err) => {
              Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
              this.isLoading = false
          })
      },
      handleAdd() {
        this.showAddPublicationModal = true
      },
      handleUpdate(data) {
        this.showEditPublicationModal = true
        let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
          API.get(`companies/types/${data.id}` , {headers})
            .then(({data:content}) => {
                this.editId = content.data.id
                this.editnameInput = content.data.name
                this.editdescriptionInput = content.data.description
              })
            .catch((err) => {
              console.log(err);
        })
      },
      doDelete(data) {
        Swal.fire({
          title: 'Hapus Data ini ? ',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'HAPUS',
          cancelButtonText: 'BATAL'
        }).then((result) => {
          if (result.isConfirmed) {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
            API.delete(`comp-types/${data.id}` , {headers})
            .then(({data:content}) => {
              Swal.fire(
              'DIHAPUS!',
              '',
              'success'
            )
  
            this.getData()
            })
            .catch((err) => {
              console.log(err);
            })
  
            
          }
        })
      },
      doAdd() {
        if (!this.nameInput || !this.descriptionInput ) {
          Swal.fire(
              'Data anda tidak lengkap!',
              'Wajib mengisi semua data.',
              'error'
          )
  
        } else {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
    
          let payload = {
            name: this.nameInput,
            description: this.descriptionInput
          }
  
  
  
          Swal.fire({
          title: 'Tambahkan Data ? ',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'TAMBAH',
          cancelButtonText: 'BATAL',
        }).then((result) => {
          if (result.isConfirmed) {
            API.post(`comp-types` , payload, {headers})
            .then(({data:content}) => {
                console.log(content);
              Swal.fire(
              'Data ditambahkan!',
              '',
              'success'
              )
  
              this.getData()
  
              this.nameInput = ""
              this.descriptionInput = ""
  
              this.showAddPublicationModal = false
            })
            .catch((err) => {
              console.log(err);
              Swal.fire(
              'Data gagal ditambahkan!',
              'Your file has not been added.',
              'error'
              )
          })
          }
        })
        }
      },
      doEdit() {
        if (!this.editnameInput || !this.editdescriptionInput ) {
          Swal.fire(
              'Data anda tidak lengkap!',
              'Wajib mengisi semua data.',
              'error'
          )
  
        } else {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
    
          let payload = {
            name: this.editnameInput,
            description: this.editdescriptionInput,
          }
  
  
  
          Swal.fire({
          title: 'Merubah Data ? ',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'RUBAH',
          cancelButtonText: 'BATAL',
        }).then((result) => {
          if (result.isConfirmed) {
            API.put(`comp-types/${this.editId}` , payload, {headers})
            .then(({data:content}) => {
              Swal.fire(
              'Data berhasil dirubah!',
              '',
              'success'
              )
  
              this.getData()
  
              this.editnameInput = ""
              this.editdescriptionInput = ""
              this.editId = ""
  
              this.showEditPublicationModal = false
            })
            .catch((err) => {
              console.log(err);
              Swal.fire(
              'Data gagal diEdit!',
              'Your file has not been edited.',
              'error'
              )
          })
          }
        })
        }
      },
      paginationChanged(page) {
          this.pagination.currentPage = page;
      },
      ConvertDateFunction(theDate) {
      if (!theDate) {
        return "00-00-0000";
      } else {
        let result = format(new Date(theDate), "dd-MM-yyyy");
        return result;
      }
    }
    },
  };
  </script>
  
  